//const baseURL = 'https://revenue.bjjoyo.com/prod-api/payment';
// const baseDomain = 'https://888.bjjoyo.com/prod-api'
//const baseURL = 'https://pinggu.4006500196.cn/prod-api/payment'
// const baseURL = 'https://uniapp.bjjoyo.com/prod-api/payment'
// const baseURL = 'https://weixian.bjjoyo.com/prod-api/payment'
// const baseURL = 'http://192.168.0.112:3000'; // 开发使用端口，方便用于真机访问
// const baseURL = 'http://132.232.76.126:8000'; // 服务器临时部署端口
// const baseDomain = 'https://wx.wxgsgs.com/prod-api'
const baseDomain = 'https://888.bjjoyo.com/prod-api'
const baseURL = baseDomain + '/payment'


export const Url = {
	homeDataApi: baseURL + '/api/goods/home', // 商城首页所有数据
	ipLocation: baseURL + '/api/ipLocation', // ip 定位
	searchApi: baseURL + '/api/search', // 搜索

	registerApi: baseURL + '/api/user/register', // 用户注册
	loginApi: baseURL + '/api/user/login', // 用户登录
	sendSMSCodeApi: baseURL + '/api/user/sendSMSCode', // 发送短信验证码
	sendPicCodeApi: baseURL + `/api/user/sendPicCode?mt=${Math.random()}`, // 发送图形验证码
	userInfoApi: baseURL + '/api/user/userInfo', // 用户信息
	updateUserInfoApi: baseURL + '/api/user/updateUserInfo', // 更新用户信息
	collectionListApi: baseURL + '/api/user/collectionList', // 用户收藏列表

	goodsListApi: baseURL + '/api/goods/goodsList', // 分类商品列表
	goodsDetailsApi: baseURL + '/api/goods/goodsDetails', // 单个商品详情
	queryCollectionApi: baseURL + '/api/user/queryCollection', // 查询商品是否已收藏
	goodsCollectionApi: baseURL + '/api/u-action/collection', // 商品收藏、取消
	addToShopCartApi: baseURL + '/api/u-action/addToShopCart', // 加入到购物车
	checkShopCartApi: baseURL + '/api/user/checkShopCart', // 查询购物车数据
	editGoodsCountApi: baseURL + '/api/u-action/editGoodsCount', // 编辑商品数量
	goodsCommentApi: baseURL + '/api/u-action/goodsComment', // 商品评论
	delCartGoodsApi: baseURL + '/api/u-action/delCartGoods', // 删除购物车商品

	addressListApi: baseURL + '/api/user/addressList', // 地址列表
	editAddressApi: baseURL + '/api/u-action/editAddress', // 编辑收货地址
	delAddressApi: baseURL + '/api/u-action/delAddress', // 编辑收货地址
	setDefAddressApi: baseURL + '/api/u-action/setDefAddress', // 设置默认地址
	defAddressApi: baseURL + '/api/user/defAddress', // 默认地址

	submitOrderApi: baseURL + '/api/u-action/submitOrder', // 提交订单
	orderListApi: baseURL + '/api/user/orderList', // 获取订单列表
	orderNumApi: baseURL + '/api/user/orderNum', // 获取订单对应处理数量

	waitCommentApi: baseURL + '/api/user/waitCommentList', // 查询待评论商品列表
	alreadyCommentApi: baseURL + '/api/user/alreadyCommentList', // 查询已评论商品列表
	commentDetailsApi: baseURL + '/api/user/commentDetails', // 评论商品详情

	getAccount: baseURL + '/api/wechat/getAccount', // 获取户号

	sendSms: baseURL + '/api/wechat/business/sms/code', //发送短信验证码
	guestNoSync: baseURL + '/api/wechat/business/guest/sync',//小程序户号一键同步

	wxUserInfo: baseURL + '/api/wechat/business/user/cur', // 获取微信用户信息
	allAccount: baseURL + '/api/wechat/business/bind/list', // 查询所有绑定户号
	bindAccount: baseURL + '/api/wechat/business/guest/bind', // 绑定手机号和户号
	newWxUserInfo: baseURL + '/api/wechat/lite/auth/user', //更新用户微信信息
	accountDetail: baseURL + '/api/wechat/business/guest/detail', // 账户详情
	incomeRecord: baseURL + '/api/wechat/business/income/detail', // 缴费记录
	useWater: baseURL + '/api/wechat/business/water/detail', // 用水明细
	switchAccount: baseURL + '/api/wechat/business/change/default', // 切换账户
	unboundAccount: baseURL + '/api/wechat/business/guest/unbind', // 解绑
	jsApiPay: baseURL + '/wx/prepay/pro/jsapi', // 微信支付jsapi签名
	jsApiPayByTenant: `${baseURL}/wx/prepay/pro/jsapi/tenant?mchType=`, // 微信支付jsapi签名,0:服务商模式;1:普通商户模式
	getWxp: baseDomain +'/system/tenant/config/carousel/wxp',//获取微信小程序配置

}
